.select-full {
    width: 100%;
}

.radio {
    margin-right: 5px;
}

.button-search {
    font-weight: bold;
}

.button-action {
    font-weight: bold;
}

.is-not-visible {
    display: none;
}

.title-container {
    margin-bottom: 11px!important;
}

.title-hr {
    margin-top: 0px;
    margin-bottom: 15px;
}

.navbar-link:not(.is-arrowless)::after, .select:not(.is-multiple):not(.is-loading)::after {
    border-left-width: 2px;
    border-bottom-width: 2px;
}

.button-link-custom {
    background-color: #265D26 !important;
}